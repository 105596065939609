import Nav from "../components/nav";
import Mobnav from "../components/mobnav";
import Footer from "../components/footer";
import FeatureBoxes from "../components/features";
import ServerPricingBox from "../components/serverPricingBox";
import FrontDoc from "../components/frontDocs";
import DashImg from "../assets/dash.png";

import "./doc.scoped.css";

function server() {
    return (
        <div>
            <Nav links={[]}/>
            <Mobnav links={[]}/>
            <FrontDoc
                dash={true}
                secText={"Last updated 1st June 2022"}
                primaryText="Acceptable Use Policy"
                imgSrc={DashImg}
                buttonText="Contact Us"
            />

            <div className="container largeText">
                <p>This Acceptable Use Policy (“AUP”) is intended to help enhance the use of the Internet by preventing unacceptable use. It is not a “terms of service” or a billing guideline. Please refer to your Terms of Service for terms and conditions applicable to your Internet Service. PLEASE READ THIS POLICY CAREFULLY BEFORE ACCESSING THE SERVICE. BY ACCESSING THE SERVICE, YOU AGREE TO BE BOUND BY THIS POLICY. IF YOU DO NOT WISH TO BE BOUND BY THIS AUP, YOU MAY NOT ACCESS OR USE THE SERVICE.</p>
                <p>This AUP applies to Internet Services (“Services”) provided by JY Mobile Communications also referred to as the (“Service Provider”). Your use of the Services indicates your acceptance of and agreement to abide by this Acceptable Use Policy. It is designed to help protect the Service, Service Provider customers and the Internet community from irresponsible or illegal activities. Service Provider may modify this AUP from time to time. In the event of any inconsistency between Service Provider Acceptable Use Policy and the terms of any service agreement, this Acceptable Use Policy shall govern and control.</p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="personal-information-we-collect">GENERAL POLICY</h3>
                <p>Service Provider reserves the right in its sole discretion to deny or restrict your use of the Services, or immediately to suspend or terminate your Services, if the use of your Services by you or anyone using it, in our sole discretion violates your Terms of Service or other Service Provider policies, is objectionable or unlawful, interferes with the functioning or use of the internet or Service Provider network by Service Provider or other users or violates the terms of this AUP.</p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="how-do-we-use-your-personal-information">ILLEGAL AND PROHIBITED USE</h3>
                <p>This section is used to address actions, content and or practices that are prohibited by law and by rules set forth by the Service Provider. Please review the sections below before using Service Provider’s Services or networks. The restrictions are not negotiable. Not all Services provided by Service Provider are listed or mentioned but are bound by this document.</p>
                <p>Users found to engage in activities that Service Provider determines, in its sole discretion, are in violation of this AUP will have their accounts terminated. Violators may also be subject to any appropriate legal action and/or consequences. Service Provider reserves the right to cooperate with legal authorities and/or injured third parties in the investigation of any suspected illegal activity or civil wrong. Activities or use of Services considered by Service Provider to be a violation of this AUP are as follows, but are not limited to:</p>
                <ul style={{paddingLeft: 40}}>
                    <li>to post or transmit information or communications that, whether explicitly stated, implied, or suggested through use of symbols, are obscene, indecent, pornographic, sadistic, cruel, or racist in content, or of a sexually explicit or graphic nature; or which espouses, promotes or incites bigotry, hatred, terrorism or racism; or which might be legally actionable for any reason;</li>
                    <li>to post, transmit, download or view any material whatsoever pornographic in nature involving actual images of children or minors or digitally or otherwise artificially created or manipulated images of children or minors, or any material whatsoever that may be deemed obscene under applicable law;</li>
                    <li>to access or attempt to access the accounts of others, to spoof or attempt to spoof the URL or DNS or IP addresses of Service Provider or any other entity, or to attempt to penetrate or penetrate security measures of Service Provider or other entities’ systems (“hacking”) whether or not the intrusion results in corruption or loss of data;</li>
                    <li>to introduce viruses, worms, harmful code and/or Trojan horses on the Internet;</li>
                    <li>to violate Service Provider or any third party’s copyright, trademark, proprietary or other intellectual property rights, including trade secret rights;</li>
                    <li>to use any name or mark of Service Provider, its parent, affiliates or subsidiaries, as a hypertext link to any Web site or in any advertising publicity or other commercial manner;</li>
                    <li>to use the Service or the Internet in a manner intended to threaten, harass, intimidate or terrorize;to make false or unverified complaints against any Service Provider subscriber, or otherwise abusing any of Service Provider complaint response procedures</li>
                    <li>Indirect or attempted violations of this AUP.</li>
                    <li>Reselling of service provided by Service Provider.</li>
                    <li>Services used to transmit, retransmit, distribute, post, or store any material that in the judgment of Service Provider is threatening, libelous, defamatory, or otherwise objectionable including but not limited to child pornography and advocating unlawful activity against any persons, animals, governments or businesses.</li>
                    <li>Harassment of users, employees, or of others will not be tolerated.</li>
                    <li>Actions and/or Services prohibited by federal, state and local law.</li>
                    <li>Distribution, posting, copying or dissemination of copyrighted material including but not limited to movies and/or music.</li>
                    <li>Inhibiting any other person’s use of the service provided by Service Provider is prohibited.</li>
                    <li>Participation in illegal gambling, lottery or other similar activities.</li>
                    <li>Transmission of scams such as “Make Money Fast” schemes.</li>
                    <li>Making fraudulent offers.</li>
                    <li>The attempt to access the accounts of other or other computers and/or networks to penetrate security measures, whether or not the intrusion results in damage. Service Provider reserves the right to limit, restrict and/or prohibit Services it provides to customers, as Service Provider determines necessary. The restrictions mentioned apply to all users unless specifically documented.</li>
                </ul>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="sharing-your-personal-information">EMAIL</h3>
                <p>Service Provider reserves the right to limit the file size of individual email mailboxes at its sole discretion. Individual email mailboxes found over the limit will be subject to deletion without notice. Deleted email will not be restored or saved. Service Provider reserves the right to limit the maximum transfer limit of any one message in its sole discretion. Service Provider reserves the right to reject or filter email based on source address and content. Examples are, but are not limited to, virus filtering and blocking open relay mail servers. Service Provider will not provide back-ups of a customer’s email.</p>
                <p>Email usage will be limited to 100 messages sent out per user, per day.</p>
                <ul style={{paddingLeft: 40}}>
                <li>The Services may not be used to transmit, retransmit, or distribute by e-mail or any other method any material that violates any condition of this AUP in the sole judgment of ISP. Activities considered by Service Provider to be a violation of this AUP are as follows, but are not limited to:</li>
                    <li>Any unsolicited e-mail, whether commercial or otherwise, including, but not limited to, bulk mailing of commercial advertising, informational announcements, and political tracts.</li>
                    <li>Solicited e-mail that contains material that otherwise violates this AUP or any e-mail that falsifies the address or other information; harassing e-mail, whether through language, frequency, or size of messages.</li>
                    <li>Any e-mail “chain letters” or other “pyramid schemes.”</li>
                    <li>E-mail relayed without the express permission of that site, service, system or network.</li>
                    <li>E-mailing the same or similar messages to one or more newsgroups (also known as “cross-posting” or “multiple posting”).</li>
                    <li>E-mail containing false or misleading statements, claims, or representations.</li>
                    <li>Forging header information including, but not limited to, any attempt to circumvent the approval process for posting to a moderated newsgroup.</li>
                </ul>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="behavioural-advertising">SPAM</h3>
                <p>Service Provider has a “zero tolerance” policy for SPAM. Any User of Service Provider Services found to be actively distributing or engaged in the mass distribution of unsolicited emails without consent of the intended receiver may have their account(s) terminated and all future access to Service Provider’s Services and network revoked.</p>
                <p>You may not use the Service or any Equipment or Software provided by Service Provider:</p>
                <ul style={{paddingLeft: 40}}>
                      <li>to send e-mail of a personal, bulk or commercial nature, including, without limitation, bulk mailings of commercial advertising, informational announcements, charity requests, political or religious messages, and petitions for signatures, except to those who have requested such e-mails via a confirmed opt-in subscription process maintained by You;</li>
                      <li>to send e-mail or other messages to someone who has indicated that he or she does not want to receive messages from You;</li>
                      <li>to collect or receive responses from unsolicited e-mail messages (even if such e-mail was sent
                        from accounts on other Internet service providers or e-mail Services) that violate the Agreement or this Acceptable Use Policy or the terms of use under the other Internet service provider or e-mail service from which it was sent;</li>
                      <li>to conduct “mail bombings” (e.g., to send more than ten copies of the same or substantially similar message, or to send very large messages or files, with the intent of disrupting a server or account);</li>
                      <li>to send or forward make-money-fast schemes or chain/pyramid letters (whether or not the recipient requests it);</li>
                      <li>to harvest e-mail addresses or personal information of other Service Provider subscribers or the subscribers of any other network; or</li>
                      <li>to use another Internet site’s mail server to relay mail without the express permission of the owner of that Internet site.</li>
                      <li>to use e-mail or the internet in violation of federal law or the rules of the Federal Communications Commission.</li>
                      <li>Multiple logons are restricted. Users are only allowed to logon once with the same account. Accounts establishing concurrent connections will be disconnected. Abuses of this service may be subject to account termination.</li>
                </ul>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="do-not-track">DSL</h3>
                <p>Service Provider restrictions to DSL Services are: Multiple logons are restricted. Users are only allowed to logon once with the same account. Accounts establishing concurrent connections will be disconnected. Abuses of this service may be subject to account termination. Modifications of the DSL modem in ways other than advised by the manufacturer are restricted.</p>
                <ul style={{paddingLeft: 40}}>
                <li>Email servers</li>
                    <li>Web – HTTP servers</li>
                    <li>FTP servers</li>
                    <li>IRC servers</li>
                    <li>Other Listening IP Services</li>
                </ul>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="your-rights">UNAUTHORIZED SHARING</h3>
                <p>You agree not to allow others to use any of the Services provided including, but not limited to, sharing your account user name and password or broadband access via any type of networking device (router, gateway, wireless access point, etc.). You may at your own risk and responsibility permit other members of your household to access the Services and utilize some networking devices approved by the provider for use within your household only. You as the account holder shall ensure that other such users are aware of and comply with these terms of use, and you agree to be held responsible for any activity or use of the Services on that account, whether or not authorized by you.</p>
                
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="data-retention">COMMERCIAL USE</h3>
                <p>Re-selling Service Provider’s Services or offering use of Service Provider’s Services for adding value to a commercial entity without Service Provider’s authorization is prohibited. Service Provider’s Services are designed for the account holder’s use of the Internet and may not be used for commercial purposes without the Service Provider’s explicit consent. You also agree not to use Service Provider’s Services for operation as an Internet Service Provider (ISP), or for any other business enterprise including, without limitation, IP address translation or similar facilities intended to provide access, operating or allowing others to operate servers of any type, or any other device, equipment and/or software providing server-like functionality in connection with Service Provider’s Services, unless expressly authorized.</p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="minors">OTHER</h3>
                <p>Other Services not specifically listed in this AUP but that are provided by Service Provider are bound by this AUP.</p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="changes">COMPLIANCE WITH THE AUP OF UPSTREAM PROVIDERS</h3>
                <p>The AUP of Service Provider’s upstream providers also bind Service Provider users. An “upstream provider” is any company that provides Service Provider bandwidth and/or other Services.</p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="contact-us">SYSTEM & NETWORK SECURITY</h3>
                <p>Violations of system or network security are prohibited and may result in criminal and civil liability. Service Provider will investigate incidents involving such violations and may involve and will cooperate with law enforcement if a criminal violation is suspected. Examples of system or network security violations include, without limitation, the following:</p>
                <ul style={{paddingLeft: 40}}>
                <li>port scanning, probes, data capture, denial of service, access of restricted systems.</li>
                    <li>attempted access of systems not previously given access to.</li>
                    <li>anything deemed “hacking” or “cracking” to the systems, network or users.</li>
                    <li>unauthorized access to or use of data, including any attempt to circumvent user authentication
                        or security of any host, network, or account (hacking, cracking, port scans, or flood pings).</li>
                    <li>unauthorized monitoring of data or traffic.</li>
                    <li>interfering with service to any user, host, system or network.</li>
                    <li>conducting denial of service attacks.</li>
                    <li>any attempt to disrupt service including, but not limited to distributing or introducing viruses,
                        worms, or other harmful software.</li>
                    <li>access by using artificial means, involving software, programming, or any other method.</li>
                </ul>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="contact-us">COPYRIGHT INFRINGEMENT/REPEAT INFRINGER POLICY</h3>
                <p>Service Provider respects the intellectual property rights of third parties, including those granted under the US copyright laws, and the interests of its subscribers and content providers on the Internet. You may not store material of any type or in any format on, or disseminate such material over, Service Provider ‘ systems or servers in any manner that constitutes an infringement of third party intellectual property rights, including rights granted under the US copyright laws. In accordance with the Digital Millennium Copyright Act (DMCA) and other applicable law, it is the policy of Service Provider to terminate, in appropriate circumstances, the Service provided to any subscriber or account holder who is deemed to infringe third party intellectual property rights, including repeat infringers. Appropriate circumstances may exist where:</p>
                <ul style={{paddingLeft: 40}}>
                <li>a Subscriber or account holder has been found by a court of competent jurisdiction to have infringed the copyrights of a third party on two or more occasions, i.e., a repeat infringer,</li>
                <li>where a valid, effective and uncontested notice has been provided to Service Provider alleging facts which are a violation by the Subscriber or account holder of Service Provider Copyright Policy prohibiting infringing activity involving Service Provider systems or servers, or</li>
                <li>in other cases of repeated flagrant abuse of access to the Internet (e.g., willful commercial piracy or malicious attempts to destroy the value of copyrighted works). In addition, Service Provider expressly reserves the right to terminate or suspend the Service of any Subscriber or account holder if Service Provider, in its sole judgment, believes that circumstances relating to the infringement of third party intellectual property rights warrant such action. These policies are in addition to and do not affect or modify any other rights Service Provider may have under law or contract.</li>
                </ul>
                <p>You shall comply with all applicable local, state, national and international laws and regulations, including those related to data privacy, international communications, and exportation or technical or personal data. You represent that you are not a specifically designated individual or entity under any US embargo or otherwise the subject, either directly or indirectly (by affiliation, or any other connection with another party) to any order issued by any agency of the US Government limiting, barring, revoking or denying, in whole or in part your US export privileges. You agree to notify Service Provider if you become subject to any such order.</p>
                <p>You shall not delete any proprietary designations, legal notices or other identifiers belonging to third parties from any information obtained or sent using the Service and shall not impersonate any person or use a false name while using the Service. You agree to obtain all required permissions if you use the Service to receive, upload, download, display, distribute, or execute Software or perform other works protected by intellectual property laws including copyright, trademark, trade secret and patent laws. You agree to cooperate with Service Provider and provide requested information in connection with all security and use matters. You agree to notify Service Provider promptly if you suspect unauthorized use of the Service or of your UserID. Service Provider reserves the right to cooperate with legal authorities and/or injured third parties in the investigation of any suspected crime or civil wrong. Such cooperation may include, but not be limited to, provision of account or user information or email as well as monitoring of the Service Provider network.</p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="contact-us">USER RESPONSIBILITY</h3>
                <p>
                    Users need to be aware that they do not operate in a vacuum. Safe practices need to be taken by the users to protect themselves and others.
                    <br></br>
                    Users are responsible for account passwords and should keep them safe.
                    <br></br>
                    Do NOT share account information.
                    <br></br>
                    Do NOT leave username and passwords in the open.
                    <br></br>
                    If a user feels that the account was compromised, the username and or password should be changed at once.
                    <br></br>
                    Do NOT “save” user names or passwords. Each should be entered at each login.
                    <br></br>
                    Users are responsible for protecting their own equipment. Anti-virus software and personal firewalls are not required but strongly encouraged.
                    <br></br>
                    Users are responsible for any misuse of Service Provider Services that occurs through user’s account.
                    <br></br>
                    Users are responsible for protecting their accounts and must take steps to ensure that others do not gain unauthorized access to user’s account or misuse Service Provider’s Services.
                </p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="contact-us">VIOLATIONS AND MONITORING</h3>
                <p>Service Provider does not intend to actively monitor the content of web sites, e-mail, news groups, or material created or accessible over its Services. Service Provider reserves the right to monitor such Services or any Services on or within our network.</p>
                <p>Reporting Violations and complaints: 
                    <br></br>
                    Violations, attempted violations, and/or concerns should be addressed to abuse@jymobile.net via email. <br></br>
                    When reporting anything to Service Provider please include:
                    <ul style={{paddingLeft: 40}}>
                    <li>The internet protocol address used to commit the alleged violation.</li>
                    <li>The date, time and time zone of such violation.</li>
                    <li>Evidence of the violation, including, if applicable, full headers from emails, firewall logs,
                        traffic dumps (example, the *.enc files generated by Network Ice’s Black Ice program or “hex”
                        dump from any other firewall or IDS system) or information pertaining to the events in question.</li>
                    <li>Do not send excerpted parts of a message; sending the entire message with full headers helps prevent misunderstandings based on incomplete information or information taken out of
                        context.</li>
                    </ul>
                </p>
                <h3 style={{
                    color: '#6f84ad',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontSize: "21px",
                    marginBottom: "15px",
                    textDecoration: "none",
                }} id="contact-us">REVISIONS</h3>
                <p>
                    Changes to this AUP are considered effective immediately and it is the end user’s responsibility to stay current. Service Provider can make changes to the AUP at any time without notice. Changes to this document will be available on Service Provider’s web page.
                    <br></br>
                    Service Provider may, but is not required to, monitor your compliance, or the compliance of other subscribers, with this Acceptable Use Policy.
                </p>
            </div>

            <Footer/>
        </div>
    );
}

export default server;
