import { useState } from "react";
import "./pricingBox.scoped.css";
import "antd/dist/antd.css";
import { Select } from "antd";
import Buttons from "./button";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const { Option } = Select;
function PricingBox(props) {
  const [selectedPack, updateSelected] = useState(props.plans[0]);

  return (
    <div className={`pricing-box ${props.nostock}`}>
      <div className="product">
        <div>{props.family || "Product"}</div>
        <div className="num">{props.num}</div>
      </div>
      <div className="padding-section">
        <div className="specs">
          {props.specs.map((e) => (
            <div className="spec">
              <div className="dot" />
              {e}
            </div>
          ))}
        </div>
        <div className="price">
          <span className="currency">{props.currency}</span>
          <span className="price-num">{selectedPack.cost.split("$")[1]}</span>
          <span className="type">{props.type}</span>
        </div>
        
        <div className="select">
          <Select
            defaultValue={0}
            onSelect={(value) => {
              updateSelected(props.plans[value]);
            }}
          >
            {props.plans.map((k, i) => (
              <Option key={i} value={i}>
                {k.time || k.carrier}
              </Option>
            ))}
          </Select>
        </div>
     
        <Buttons
          text="Purchase"
          disabled={!selectedPack.stock ? true : false}
          className={!selectedPack.stock ? "no-stock" : ""}
          onClick={() => {
            toast("Client Portal Coming Soon!")
            // window.location = selectedPack.purchaseLink;
          }}
        />
        <ToastContainer />
      </div>
    </div>
  );
}

export default PricingBox;
