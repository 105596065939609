import "./about.scoped.css";
import logo from "../assets/logo.svg";
function about() {
  return (
    <div className="about container">
      <div className="left-section">
        <div className="logoimg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="216"
            height="211"
            viewBox="0 0 216 211"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M107.674 210.749C158.979 210.749 200.571 208.55 200.571 205.838C200.571 203.126 158.979 200.928 107.674 200.928C56.368 200.928 14.7766 203.126 14.7766 205.838C14.7766 208.55 56.368 210.749 107.674 210.749Z"
              fill="#00091E"
            />
            <path
              d="M204.113 190.31H11.2345C8.25479 190.309 5.39724 189.125 3.29039 187.018C1.18354 184.911 -4.19923e-05 182.053 -4.19617e-05 179.074V156.984C0.000384926 154.005 1.18416 151.148 3.29096 149.041C5.39776 146.934 8.25507 145.75 11.2345 145.75H204.113C207.092 145.75 209.949 146.934 212.056 149.041C214.163 151.148 215.347 154.005 215.347 156.984V179.074C215.346 182.053 214.162 184.91 212.055 187.017C209.949 189.124 207.092 190.308 204.113 190.31Z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M204.211 145.753H11.1315L28.0076 132.251C29.1009 131.377 30.4589 130.901 31.8587 130.901H183.482C184.882 130.9 186.239 131.376 187.332 132.251L204.211 145.753Z"
              fill="url(#paint1_linear)"
            />
            <path
              d="M11.1315 190.31H204.211L187.335 203.809C186.242 204.684 184.885 205.161 183.485 205.162H31.8587C30.4584 205.163 29.0996 204.686 28.0076 203.809L11.1315 190.31Z"
              fill="url(#paint2_linear)"
            />
            <path
              opacity="0.2"
              d="M185.646 156.892H196.785C197.77 156.892 198.714 157.284 199.41 157.98C200.107 158.676 200.498 159.62 200.498 160.605C200.498 161.59 200.107 162.534 199.41 163.23C198.714 163.927 197.77 164.318 196.785 164.318H185.646C184.661 164.318 183.717 163.927 183.02 163.23C182.324 162.534 181.933 161.59 181.933 160.605C181.933 159.62 182.324 158.676 183.02 157.98C183.717 157.284 184.661 156.892 185.646 156.892Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M185.646 171.745H196.785C197.77 171.745 198.714 172.136 199.41 172.832C200.107 173.528 200.498 174.473 200.498 175.457C200.498 176.442 200.107 177.386 199.41 178.083C198.714 178.779 197.77 179.17 196.785 179.17H185.646C184.661 179.17 183.717 178.779 183.02 178.083C182.324 177.386 181.933 176.442 181.933 175.457C181.933 174.473 182.324 173.528 183.02 172.832C183.717 172.136 184.661 171.745 185.646 171.745Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M159.654 156.892H170.794C171.778 156.892 172.723 157.284 173.419 157.98C174.115 158.676 174.506 159.62 174.506 160.605C174.506 161.59 174.115 162.534 173.419 163.23C172.723 163.927 171.778 164.318 170.794 164.318H159.654C158.67 164.318 157.725 163.927 157.029 163.23C156.333 162.534 155.942 161.59 155.942 160.605C155.942 159.62 156.333 158.676 157.029 157.98C157.725 157.284 158.67 156.892 159.654 156.892Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M159.654 171.745H170.794C171.778 171.745 172.723 172.136 173.419 172.832C174.115 173.528 174.506 174.473 174.506 175.457C174.506 176.442 174.115 177.386 173.419 178.083C172.723 178.779 171.778 179.17 170.794 179.17H159.654C158.67 179.17 157.725 178.779 157.029 178.083C156.333 177.386 155.942 176.442 155.942 175.457C155.942 174.473 156.333 173.528 157.029 172.832C157.725 172.136 158.67 171.745 159.654 171.745Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M144.802 164.318H133.664C132.679 164.318 131.735 163.927 131.039 163.23C130.342 162.534 129.951 161.59 129.951 160.605C129.951 159.62 130.342 158.676 131.039 157.98C131.735 157.284 132.679 156.892 133.664 156.892H144.802C145.786 156.892 146.731 157.284 147.427 157.98C148.123 158.676 148.514 159.62 148.514 160.605C148.514 161.59 148.123 162.534 147.427 163.23C146.731 163.927 145.786 164.318 144.802 164.318Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M133.664 171.745H144.802C145.289 171.745 145.772 171.841 146.223 172.027C146.673 172.214 147.082 172.487 147.427 172.832C147.772 173.177 148.045 173.586 148.232 174.037C148.418 174.487 148.514 174.97 148.514 175.457C148.514 175.945 148.418 176.428 148.232 176.878C148.045 177.328 147.772 177.738 147.427 178.083C147.082 178.427 146.673 178.701 146.223 178.887C145.772 179.074 145.289 179.17 144.802 179.17H133.664C133.176 179.17 132.693 179.074 132.243 178.887C131.793 178.701 131.383 178.427 131.039 178.083C130.694 177.738 130.42 177.328 130.234 176.878C130.047 176.428 129.951 175.945 129.951 175.457C129.951 174.97 130.047 174.487 130.234 174.037C130.42 173.586 130.694 173.177 131.039 172.832C131.383 172.487 131.793 172.214 132.243 172.027C132.693 171.841 133.176 171.745 133.664 171.745Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M103.959 160.605C106.01 160.605 107.672 158.943 107.672 156.892C107.672 154.842 106.01 153.18 103.959 153.18C101.909 153.18 100.247 154.842 100.247 156.892C100.247 158.943 101.909 160.605 103.959 160.605Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M103.959 182.883C106.01 182.883 107.672 181.22 107.672 179.17C107.672 177.12 106.01 175.457 103.959 175.457C101.909 175.457 100.247 177.12 100.247 179.17C100.247 181.22 101.909 182.883 103.959 182.883Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M96.5321 171.743C98.5826 171.743 100.245 170.081 100.245 168.031C100.245 165.98 98.5826 164.318 96.5321 164.318C94.4817 164.318 92.8195 165.98 92.8195 168.031C92.8195 170.081 94.4817 171.743 96.5321 171.743Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M89.1068 160.605C91.1572 160.605 92.8194 158.943 92.8194 156.892C92.8194 154.842 91.1572 153.18 89.1068 153.18C87.0563 153.18 85.3941 154.842 85.3941 156.892C85.3941 158.943 87.0563 160.605 89.1068 160.605Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M89.1068 182.883C91.1572 182.883 92.8194 181.22 92.8194 179.17C92.8194 177.12 91.1572 175.457 89.1068 175.457C87.0563 175.457 85.3941 177.12 85.3941 179.17C85.3941 181.22 87.0563 182.883 89.1068 182.883Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M81.6805 171.743C83.7309 171.743 85.3931 170.081 85.3931 168.031C85.3931 165.98 83.7309 164.318 81.6805 164.318C79.63 164.318 77.9678 165.98 77.9678 168.031C77.9678 170.081 79.63 171.743 81.6805 171.743Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M74.2549 160.605C76.3054 160.605 77.9676 158.943 77.9676 156.892C77.9676 154.842 76.3054 153.18 74.2549 153.18C72.2045 153.18 70.5423 154.842 70.5423 156.892C70.5423 158.943 72.2045 160.605 74.2549 160.605Z"
              fill="#546A79"
            />
            <path
              opacity="0.2"
              d="M74.2549 182.883C76.3054 182.883 77.9676 181.22 77.9676 179.17C77.9676 177.12 76.3054 175.457 74.2549 175.457C72.2045 175.457 70.5423 177.12 70.5423 179.17C70.5423 181.22 72.2045 182.883 74.2549 182.883Z"
              fill="#546A79"
            />
            <path
              opacity="0.5"
              d="M25.9834 179.17C32.1356 179.17 37.123 174.183 37.123 168.031C37.123 161.878 32.1356 156.891 25.9834 156.891C19.8312 156.891 14.8438 161.878 14.8438 168.031C14.8438 174.183 19.8312 179.17 25.9834 179.17Z"
              fill="#283238"
            />
            <path
              className="blink"
              d="M25.984 175.458C30.0858 175.458 33.4109 172.133 33.4109 168.031C33.4109 163.929 30.0858 160.604 25.984 160.604C21.8822 160.604 18.5571 163.929 18.5571 168.031C18.5571 172.133 21.8822 175.458 25.984 175.458Z"
              fill="url(#paint3_radial)"
            />
            <path
              opacity="0.2"
              d="M172.396 145.704C171.291 151.863 163.642 154.646 154.373 154.646C147.611 154.646 141.704 153.164 138.582 149.968C128.604 153.209 118.161 154.79 107.67 154.646C99.1023 154.712 90.5602 153.696 82.2468 151.621C80.8703 151.253 79.4953 150.858 78.1993 150.436C77.7163 150.275 77.2236 150.126 76.7616 149.968C73.6398 153.164 67.7456 154.646 60.9723 154.646C51.7003 154.646 44.0528 151.863 42.9403 145.704H172.396Z"
              fill="black"
            />
            <path
              d="M148.292 54.0314L134.27 70.5532L77.7094 70.4228L67.0544 54.0314C68.8895 51.0305 71.1695 48.3254 73.8164 46.0088C81.8354 39.2597 91.7773 35.2092 102.23 34.4329C104.039 34.252 105.856 34.1644 107.675 34.1705C112.266 34.1647 116.841 34.7123 121.302 35.8014C125.681 36.8509 129.901 38.479 133.85 40.6427C139.754 43.7624 144.735 48.3797 148.292 54.0314Z"
              fill="url(#paint4_linear)"
            />
            <path
              d="M168.181 113.093C168.246 118.546 166.916 123.925 164.317 128.719L139.04 147.878C128.906 151.121 118.313 152.7 107.674 152.554C98.9879 152.62 90.3276 151.604 81.8929 149.528C80.4986 149.16 79.1044 148.765 77.789 148.344C77.2899 148.183 76.7892 148.022 76.3159 147.87L51.0389 128.711C49.6723 126.236 48.6625 123.58 48.0394 120.822C47.4522 118.288 47.1609 115.695 47.1716 113.094C47.1716 112.069 47.2232 111.016 47.3037 109.99C48.0826 102.047 50.753 94.4066 55.0912 87.7077C50.3804 73.2902 46.1976 49.9564 55.0639 49.9564C61.9563 49.9564 74.5046 58.058 82.3453 63.6092C90.2591 59.8419 98.9091 57.874 107.674 57.847C108.753 57.847 109.831 57.8728 110.91 57.9533C118.593 58.3875 126.117 60.3175 133.06 63.635C135.475 61.9252 138.348 59.9513 141.347 58.0563C148.109 53.8478 155.579 49.9548 160.368 49.9548C169.234 49.9548 165.024 73.4206 160.314 87.8108C165.234 95.3438 167.958 104.098 168.181 113.093Z"
              fill="url(#paint5_linear)"
            />
            <path
              d="M77.1044 66.3978C70.0982 70.5122 63.9239 75.9013 58.9001 82.2869C55.1118 69.1332 54.4275 58.2673 55.6898 55.2695C57.8472 55.5061 63.7929 57.1886 77.1044 66.3978Z"
              fill="#55758E"
            />
            <path
              d="M81.8649 136.138C81.8773 140.543 80.4454 144.83 77.7883 148.343C77.2892 148.182 76.7885 148.021 76.3152 147.87L51.0382 128.711C49.6716 126.236 48.6618 123.58 48.0388 120.822C51.7281 117.523 56.5073 115.705 61.4565 115.718C66.8691 115.725 72.0581 117.879 75.8843 121.707C79.7106 125.535 81.8615 130.725 81.8649 136.138Z"
              fill="#B7CDE5"
            />
            <path
              d="M102.412 36.8009C102.414 40.1415 101.355 43.3965 99.3888 46.0971C97.4224 48.7976 94.6496 50.8043 91.4697 51.8281C88.2899 52.852 84.8671 52.8401 81.6944 51.7943C78.5217 50.7485 75.7629 48.7226 73.8152 46.0085C81.8342 39.2594 91.7761 35.2089 102.228 34.4326C102.357 35.2154 102.418 36.0077 102.412 36.8009Z"
              fill="#B7CDE5"
            />
            <path
              d="M133.981 42.0627C133.982 43.4046 133.641 44.7246 132.989 45.8978C132.338 47.0711 131.398 48.0588 130.259 48.7675C129.119 49.4763 127.818 49.8827 126.477 49.9483C125.137 50.0139 123.802 49.7365 122.599 49.1424C121.396 48.5482 120.364 47.657 119.601 46.553C118.838 45.4489 118.37 44.1686 118.24 42.833C118.11 41.4974 118.323 40.1507 118.858 38.9203C119.394 37.6899 120.234 36.6164 121.3 35.8014C125.68 36.8509 129.9 38.479 133.849 40.6427C133.932 41.1115 133.976 41.5865 133.981 42.0627Z"
              fill="#B7CDE5"
            />
            <path
              className="tail"
              d="M114.436 34.5648C114.586 30.4308 114.161 26.2961 113.174 22.2789C112.756 20.3243 112.9 18.2913 113.589 16.4151C114.278 14.5389 115.484 12.8962 117.068 11.677C121.567 8.65182 127.643 12.1504 130.879 14.5187C132.006 15.3419 133.413 15.6838 134.792 15.4691C136.171 15.2545 137.409 14.5008 138.232 13.374C139.055 12.2471 139.397 10.8394 139.182 9.46044C138.968 8.08151 138.214 6.84433 137.087 6.02108C123.933 -3.60672 114.621 0.654951 111.201 2.917C107.821 5.3326 105.234 8.6973 103.768 12.5847C102.302 16.4721 102.023 20.7073 102.966 24.7534C103.731 27.8759 104.056 31.0899 103.932 34.3023L114.436 34.5648Z"
              fill="url(#paint6_linear)"
            />
            <path
              d="M78.7356 142.031C78.7356 134.766 70.4908 126.253 60.3204 126.253C50.15 126.253 41.9052 134.773 41.9052 142.031C41.9052 149.288 50.15 152.553 60.3204 152.553C70.4908 152.553 78.7356 149.295 78.7356 142.031Z"
              fill="#A3BCD3"
            />
            <path
              d="M173.442 142.031C173.442 134.766 165.197 126.253 155.027 126.253C144.856 126.253 136.611 134.773 136.611 142.031C136.611 149.288 144.856 152.553 155.027 152.553C165.197 152.553 173.442 149.295 173.442 142.031Z"
              fill="#A3BCD3"
            />
            <path
              d="M60.3206 155.184C58.2293 155.181 56.2247 154.349 54.746 152.87C53.2672 151.391 52.435 149.387 52.4316 147.295V142.034C52.4316 141.336 52.7088 140.667 53.2021 140.174C53.6955 139.68 54.3646 139.403 55.0623 139.403C55.76 139.403 56.4292 139.68 56.9225 140.174C57.4159 140.667 57.6931 141.336 57.6931 142.034V147.295C57.6931 147.641 57.7611 147.983 57.8933 148.302C58.0255 148.621 58.2193 148.911 58.4636 149.156C58.7079 149.4 58.9979 149.594 59.3171 149.726C59.6362 149.858 59.9783 149.926 60.3238 149.926C60.6693 149.926 61.0114 149.858 61.3306 149.726C61.6497 149.594 61.9397 149.4 62.184 149.156C62.4283 148.911 62.6221 148.621 62.7543 148.302C62.8865 147.983 62.9545 147.641 62.9545 147.295V142.034C62.9545 141.336 63.2317 140.667 63.7251 140.174C64.2184 139.68 64.8876 139.403 65.5853 139.403C66.283 139.403 66.9521 139.68 67.4455 140.174C67.9389 140.667 68.216 141.336 68.216 142.034V147.295C68.2126 149.388 67.3795 151.393 65.8994 152.872C64.4192 154.351 62.413 155.183 60.3206 155.184Z"
              fill="#6F93AD"
            />
            <path
              d="M155.027 155.184C152.936 155.181 150.931 154.349 149.452 152.87C147.973 151.391 147.141 149.387 147.138 147.295V142.034C147.138 141.336 147.415 140.667 147.908 140.174C148.402 139.68 149.071 139.403 149.769 139.403C150.466 139.403 151.135 139.68 151.629 140.174C152.122 140.667 152.399 141.336 152.399 142.034V147.295C152.399 147.993 152.676 148.662 153.17 149.156C153.663 149.649 154.332 149.926 155.03 149.926C155.728 149.926 156.397 149.649 156.89 149.156C157.384 148.662 157.661 147.993 157.661 147.295V142.034C157.661 141.336 157.938 140.667 158.431 140.174C158.925 139.68 159.594 139.403 160.292 139.403C160.989 139.403 161.658 139.68 162.152 140.174C162.645 140.667 162.922 141.336 162.922 142.034V147.295C162.919 149.388 162.086 151.394 160.606 152.873C159.126 154.352 157.119 155.183 155.027 155.184Z"
              fill="#6F93AD"
            />
            <path
              d="M100.939 141.881C98.5589 141.878 96.277 140.932 94.594 139.249C92.911 137.566 91.9643 135.284 91.9617 132.904V130.65C91.9617 130.054 92.1982 129.483 92.6191 129.063C93.04 128.642 93.6109 128.405 94.2061 128.405C94.8013 128.405 95.3722 128.642 95.7931 129.063C96.214 129.483 96.4504 130.054 96.4504 130.65V132.904C96.4727 134.08 96.9555 135.2 97.795 136.024C98.6345 136.847 99.7637 137.309 100.94 137.309C102.116 137.309 103.245 136.847 104.085 136.024C104.924 135.2 105.407 134.08 105.429 132.904V123.925C105.429 123.329 105.666 122.758 106.087 122.338C106.508 121.917 107.079 121.68 107.674 121.68C108.269 121.68 108.84 121.917 109.261 122.338C109.682 122.758 109.918 123.329 109.918 123.925V132.904C109.916 135.284 108.969 137.566 107.285 139.249C105.602 140.932 103.32 141.879 100.939 141.881Z"
              fill="#8BAAC6"
            />
            <path
              d="M114.406 141.881C112.026 141.878 109.744 140.932 108.061 139.249C106.378 137.566 105.432 135.284 105.429 132.904V123.925C105.429 123.329 105.666 122.758 106.086 122.338C106.507 121.917 107.078 121.68 107.673 121.68C108.269 121.68 108.84 121.917 109.26 122.338C109.681 122.758 109.918 123.329 109.918 123.925V132.904C109.94 134.08 110.423 135.2 111.262 136.024C112.102 136.847 113.231 137.309 114.407 137.309C115.583 137.309 116.713 136.847 117.552 136.024C118.392 135.2 118.874 134.08 118.897 132.904V130.65C118.912 130.064 119.156 129.509 119.575 129.1C119.995 128.692 120.557 128.464 121.142 128.464C121.727 128.464 122.289 128.692 122.709 129.1C123.128 129.509 123.371 130.064 123.387 130.65V132.904C123.384 135.284 122.437 137.566 120.753 139.249C119.07 140.932 116.787 141.879 114.406 141.881Z"
              fill="#8BAAC6"
            />
            <path
              d="M68.2087 104.766C67.6432 104.766 67.0929 104.582 66.6406 104.242C58.438 99.1535 48.9194 96.5907 39.2706 96.8735C38.5729 96.8735 37.9037 96.5963 37.4104 96.103C36.917 95.6096 36.6398 94.9404 36.6398 94.2427C36.6398 93.545 36.917 92.8759 37.4104 92.3825C37.9037 91.8892 38.5729 91.612 39.2706 91.612C58.3442 91.612 69.3293 99.6861 69.7865 100.031C70.2283 100.362 70.5545 100.824 70.719 101.351C70.8835 101.879 70.8779 102.444 70.703 102.968C70.528 103.492 70.1927 103.948 69.7444 104.27C69.2962 104.593 68.7578 104.766 68.2055 104.766H68.2087Z"
              fill="#8BAAC6"
            />
            <path
              d="M39.2762 117.919C38.7113 117.92 38.1613 117.738 37.7075 117.402C37.2537 117.065 36.9202 116.592 36.7564 116.051C36.5926 115.511 36.6072 114.932 36.798 114.4C36.9889 113.868 37.3457 113.412 37.8159 113.099C46.2733 107.464 62.2734 107.396 62.9512 107.396C63.2967 107.396 63.6388 107.464 63.958 107.596C64.2772 107.729 64.5672 107.922 64.8115 108.167C65.0557 108.411 65.2495 108.701 65.3817 109.02C65.5139 109.339 65.582 109.682 65.582 110.027C65.582 110.372 65.5139 110.715 65.3817 111.034C65.2495 111.353 65.0557 111.643 64.8115 111.887C64.5672 112.131 64.2772 112.325 63.958 112.457C63.6388 112.59 63.2967 112.658 62.9512 112.658C62.8031 112.658 47.8639 112.724 40.7332 117.476C40.3019 117.765 39.7949 117.919 39.2762 117.919Z"
              fill="#8BAAC6"
            />
            <path
              d="M147.141 104.761C146.589 104.763 146.05 104.59 145.601 104.269C145.153 103.947 144.817 103.492 144.641 102.968C144.466 102.444 144.46 101.878 144.624 101.351C144.789 100.824 145.115 100.362 145.557 100.031C146.013 99.6861 157.001 91.612 176.073 91.612C176.771 91.612 177.44 91.8892 177.933 92.3825C178.427 92.8759 178.704 93.545 178.704 94.2427C178.704 94.9404 178.427 95.6096 177.933 96.1029C177.44 96.5963 176.771 96.8735 176.073 96.8735C166.424 96.5883 156.904 99.1518 148.703 104.244C148.251 104.579 147.704 104.76 147.141 104.761Z"
              fill="#8BAAC6"
            />
            <path
              d="M176.07 117.919C175.554 117.918 175.05 117.764 174.621 117.476C167.492 112.724 152.553 112.658 152.403 112.658C152.058 112.658 151.715 112.59 151.396 112.457C151.077 112.325 150.787 112.131 150.543 111.887C150.299 111.643 150.105 111.353 149.973 111.034C149.84 110.715 149.772 110.372 149.772 110.027C149.772 109.682 149.84 109.339 149.973 109.02C150.105 108.701 150.299 108.411 150.543 108.167C150.787 107.922 151.077 107.729 151.396 107.596C151.715 107.464 152.058 107.396 152.403 107.396C153.081 107.396 169.083 107.461 177.54 113.099C178.01 113.412 178.367 113.869 178.558 114.401C178.749 114.933 178.763 115.512 178.599 116.053C178.435 116.594 178.101 117.067 177.646 117.403C177.192 117.739 176.642 117.92 176.076 117.919H176.07Z"
              fill="#8BAAC6"
            />
            <path
              d="M116.765 119.165L111.714 124.552C110.625 125.589 109.178 126.168 107.674 126.168C106.17 126.168 104.723 125.589 103.634 124.552L98.5814 119.165C98.158 118.75 97.8521 118.23 97.6946 117.658C97.5371 117.087 97.5336 116.483 97.6846 115.91C98.0014 114.991 98.5936 114.192 99.3806 113.622C100.168 113.051 101.111 112.737 102.083 112.722C102.262 112.704 102.442 112.697 102.622 112.701H112.724C112.903 112.697 113.083 112.704 113.261 112.722C114.233 112.741 115.175 113.056 115.962 113.626C116.749 114.196 117.342 114.993 117.663 115.91C117.813 116.484 117.809 117.087 117.652 117.658C117.494 118.23 117.188 118.75 116.765 119.165Z"
              fill="#55758E"
            />
            <path
              d="M117.663 115.91C117.499 116.369 117.182 116.758 116.765 117.01L111.714 120.602C110.485 121.307 109.092 121.679 107.674 121.679C106.256 121.679 104.863 121.307 103.634 120.602L98.5815 117.01C98.1652 116.757 97.8485 116.369 97.6848 115.91C98.0016 114.991 98.5938 114.192 99.3808 113.622C100.168 113.051 101.111 112.737 102.083 112.722C102.262 112.704 102.443 112.697 102.623 112.701H112.724C112.903 112.697 113.083 112.704 113.262 112.722C114.233 112.741 115.175 113.056 115.962 113.626C116.749 114.196 117.342 114.993 117.663 115.91Z"
              fill="#6588A0"
            />
            <path
              d="M114.406 116.068C114.406 114.828 111.392 113.823 107.673 113.823C103.954 113.823 100.938 114.828 100.938 116.068C100.938 117.307 103.954 118.312 107.673 118.312C111.392 118.312 114.406 117.307 114.406 116.068Z"
              fill="#7EA3B7"
            />
            <path
              d="M144.503 68.3699C144.503 73.2539 142.563 77.9379 139.11 81.3914C135.656 84.845 130.972 86.7851 126.088 86.7851C121.204 86.7851 116.52 84.845 113.067 81.3914C109.613 77.9379 107.673 73.2539 107.673 68.3699C107.659 64.6482 108.789 61.0121 110.909 57.9532C118.592 58.3874 126.116 60.3174 133.06 63.6349C135.475 61.9251 138.347 59.9512 141.346 58.0563C143.419 61.0953 144.519 64.6917 144.503 68.3699Z"
              fill="#B7CDE5"
            />
            <path
              d="M156.5 82.3656C151.476 75.9577 145.292 70.5509 138.27 66.4266C140.4 64.9535 142.347 63.6638 144.163 62.5063C153.424 56.7457 157.869 55.4818 159.736 55.2725C160.973 58.2671 160.321 69.1861 156.5 82.3656Z"
              fill="#55758E"
            />
            <path
              d="M127.031 109.956C130.318 109.91 132.927 105.952 132.859 101.116C132.791 96.2806 130.071 92.3981 126.784 92.4445C123.497 92.491 120.887 96.4488 120.956 101.285C121.024 106.12 123.744 110.003 127.031 109.956Z"
              fill="#8BAAC6"
            />
            <path
              d="M129.502 99.8159C130.419 98.2778 129.804 96.2205 128.128 95.2209C126.452 94.2212 124.349 94.6577 123.432 96.1957C122.515 97.7338 123.13 99.791 124.806 100.791C126.483 101.79 128.585 101.354 129.502 99.8159Z"
              fill="#BED7EA"
            />
            <path
              d="M87.9089 109.957C91.1959 109.911 93.805 105.953 93.7367 101.117C93.6683 96.2815 90.9483 92.399 87.6613 92.4455C84.3743 92.4919 81.7652 96.4498 81.8335 101.286C81.9019 106.121 84.6219 110.004 87.9089 109.957Z"
              fill="#8BAAC6"
            />
            <path
              d="M90.3773 99.8133C91.2945 98.2752 90.6792 96.218 89.0029 95.2183C87.3266 94.2186 85.2241 94.6551 84.3069 96.1932C83.3896 97.7312 84.005 99.7885 85.6813 100.788C87.3576 101.788 89.4601 101.351 90.3773 99.8133Z"
              fill="#BED7EA"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-0.00648196"
                y1="168.029"
                x2="215.354"
                y2="168.029"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#38454F" />
                <stop offset="1" stop-color="#24323A" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="11.1315"
                y1="138.326"
                x2="204.211"
                y2="138.326"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#283238" />
                <stop offset="1" stop-color="#192328" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="77312.3"
                y1="23822.8"
                x2="308863"
                y2="23822.8"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#283238" />
                <stop offset="1" stop-color="#192328" />
              </linearGradient>
              <radialGradient
                id="paint3_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25.984 168.031) scale(7.42693)"
              >
                <stop stop-color="#A6FC4C" />
                <stop offset="1" stop-color="#74CC17" />
              </radialGradient>
              <linearGradient
                id="paint4_linear"
                x1="107.673"
                y1="70.5532"
                x2="107.673"
                y2="34.1705"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DFE8F4" />
                <stop offset="1" stop-color="#E8EFFC" />
              </linearGradient>
              <linearGradient
                id="paint5_linear"
                x1="107.675"
                y1="152.563"
                x2="107.675"
                y2="49.9548"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#EBF1FF" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
              <linearGradient
                id="paint6_linear"
                x1="120.829"
                y1="34.5648"
                x2="120.829"
                y2="-0.000319337"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#BFCDDD" />
                <stop offset="1" stop-color="#D3DDED" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="cta">
          <div className="top-section">
            <div className="circles">
              <div className="red circle" />
              <div className="yel circle" />
              <div className="gre circle" />
            </div>
            <div className="center">JY Mobile</div>
            <div />
          </div>
          <div className="bottom-section">
            <div className="line1">Our main services include the following</div>
            <div className="pl">
              <div />
              <div>- &nbsp; Dedicated Internet Access (Home / Business)</div>
              <div>- &nbsp; Cloud VPS</div>
              <div>- &nbsp; Baremetal</div>
              <div>- &nbsp; IP Transit</div>
              <div>- &nbsp; Colocation</div>
              <div>+ &nbsp; Many More</div>
            </div>
            <div className="twitter flex al-ce">
              Contact us <span>@jymobile</span>
              <div class="blinking-cursor">|</div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="header">About Us</div>
        <div className="text">
        JY Mobile is dedicated to providing customers with the best and affordable internet and cloud service solution.
        </div>
      </div>
    </div>
  );
}

export default about;
