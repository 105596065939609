import "./faq.scoped.css";
import "antd/dist/antd.css";
import { Collapse } from "antd";
const { Panel } = Collapse;
function faq() {
  return (
    <div className="faq" id="faq">
      <div className="container">
        <div className="header">Frequently Asked Questions</div>
        <div className="faq-section">
          <Collapse bordered={false}>
            <Panel
              header="What services do you provide?"
              key="1"
            >
              The services we provide includes dedicated internet access (home & business), cloud vps, IP transit, colocation.
            </Panel>
            <Panel
              header="Where is your service location？"
              key="2"
            >
              Our main service locations are in Tokyo (Japan), Dallas (Texas, USA), and Ashburn (Virginia, USA) for now. But we will expand our business into more locations soon.
            </Panel>
            <Panel
              header="How is your customer support?"
              key="3"
            >
              Our support staffs work in different time zones, which guarantees a live time support.
            </Panel>
            <Panel
              header="Do you offer managed solutions?"
              key="4"
            >
              Yes, we do. Our team consists of experienced networking engieers, system administrators, web developers and etc. We can definitely fulfill any requests across the whole seven layers. 
            </Panel>
            <Panel
              header="Do you offer customized cloud services?"
              key="5"
            >
              Yes, we do. Feel free to contact us to get a quote with a detailed requirement. 
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default faq;
