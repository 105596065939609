import Nav from "../components/nav";
import Mobnav from "../components/mobnav";
import Footer from "../components/footer";
import FeatureBoxes from "../components/features";
import ServerPricingBox from "../components/serverPricingBox";
import FrontPage from "../components/frontPage";
import Pricing from "../components/diaPricing";
import DashImg from "../assets/dash.png";

function server() {
  return (
    <div>
      <Nav links={[]} />
      <Mobnav links={[]} />
      <FrontPage
        dash={true}
        secText="We provide both home and business internet."
        primaryText="Dedicated Internet Access"
        imgSrc={DashImg}
        buttonText="Request a Quote"
        href="../#contact"
      />
      <Pricing />
      <Footer />
    </div>
  );
}

export default server;
