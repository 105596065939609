import Box from "./productBox";
import lock from "../assets/lock.png";
import ip from "../assets/ip.png";
import soln from "../assets/soln.png";
import "./products.scoped.css";

function products() {
  return (
    <div className="products container" id="service">
      <div className="header">Services</div>
      <div className="boxes">
        <Box
          to="/dedicated-internet"
          imgSrc={soln}
          text1="Dedicated Internet"
          text2="We provide both home and business internet"
        />
        <Box
          to="/cloud-vps"
          imgSrc={lock}
          text1="Cloud VPS"
          text2="We provide powerful and affordable Cloud VPS"
        />
        <Box
          to="/colocation"
          imgSrc={ip}
          text1="Colocation"
          text2="We provide from 1U to a multi-cabinet infrastructure"
        />
      </div>
    </div>
  );
}

export default products;
