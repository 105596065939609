import Buttons from "../components/button";
import "./hero.scoped.css";
import mobBg from "../assets/mobbg.png";

function hero() {
  return (
    <div className="hero container jc-ce flex-col">
      <div className="primary-text focus-in-expand">
        Your Most Reliable Internet Provider.
      </div>
      <div className="sec-text focus-in-expand">
        Connects you to the future.
      </div>
      <a href="#service">
        <Buttons text="View Services" className="plans" />
      </a>
      <div className="mob-only">
        <img src={mobBg} alt="" />
      </div>
    </div>
  );
}

export default hero;
