import FeaturesBox from "./featureBox";
import globe from "../assets/globe.svg";
import target from "../assets/target.svg";
import storage from "../assets/storage.svg";
import like from "../assets/like.svg";
import api from "../assets/api.svg";
import support from "../assets/support.svg";
import "./features.scoped.css";
function features() {
  return (
    <div className="featureBoxes" id="features">
      <div className="container ">
        <div className="header">Features</div>
        <div className="grid">
          <FeaturesBox
            imgSrc={globe}
            header="Connectivity Option"
            text="We can provide our own solution or cross connect to any on-site carriers you want to."
          />
          <FeaturesBox
            imgSrc={target}
            header="DDoS Protection"
            text="We can provide managed DDoS protection with 24*7 alerts & monitoring."
          />
          <FeaturesBox
            imgSrc={storage}
            header="Free Racking"
            text="We will provide initial racking for free. All you need to do is to ship your equipment."
          />
          <FeaturesBox
            imgSrc={api}
            header="Expert Account Manager"
            text="Our experts review your needs and provide state-of-the-art and competitive solutions."
          />
          <FeaturesBox
            imgSrc={like}
            header="Experienced Support Team"
            text="Our network engineers and system admins are always here to help you troubleshoot."
          />
          <FeaturesBox
            imgSrc={support}
            header="Affordable Remote Hands"
            text="Your business benefits with the convenience and flexibility of our as-needed hourly rates."
          />
        </div>
      </div>
    </div>
  );
}

export default features;
