import FeaturesBox from "../components/featureBox";
import perform from "../assets/perform.svg";
import xeon from "../assets/xeon.svg";
import ssd from "../assets/ssd.svg";
import delivery from "../assets/delivery.svg";
import support from "../assets/support.svg";
import mobile from "../assets/mobile.svg";
import "./features.scoped.css";
function features() {
  return (
    <div className="featureBoxes" id="features">
      <div className="container ">
        <div className="header">Features</div>
        <div className="grid">
          <FeaturesBox
            imgSrc={perform}
            header="High Performance"
            text="Our Virtual Private Servers are built on clusters of Enterprise grade servers."
          />
          <FeaturesBox
            imgSrc={ssd}
            header="Solid-State Drives"
            text="SSD drives can give you a significant speed boost in a number of ways."
          />
          <FeaturesBox
            imgSrc={xeon}
            header="No Downtime"
            text="We provide you with a 100% uptime guarantee for our servers and network."
          />
          <FeaturesBox
            imgSrc={delivery}
            header="Fast Delivery"
            text="We have an automated delivery system to ensure the smooth delivery process."
          />
          <FeaturesBox
            imgSrc={mobile}
            header="Solid Network"
            text="Our servers is running on a dedicated 10Gig multi-homed network."
          />
          <FeaturesBox
            imgSrc={support}
            header="Friendly Support"
            text="As a client, you will have access to our portal. Open tickets anytime for assistance."
          />
        </div>
      </div>
    </div>
  );
}

export default features;
